import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../api';

// Async thunk for handling login
export const getSkills = createAsyncThunk('skills', async (_, { rejectWithValue }) => {
	try {
		const response = await API.get('skills', null);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

const skillsSlice = createSlice({
	name: 'skills',
	initialState: {
		skills: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSkills.fulfilled, (state, action) => {
				state.skills = action.payload;
			})
			.addCase(getSkills.rejected, (state, action) => {
				state.skills = [];
				console.error(action);
			});
	},
});

//export const { } = projectsSlice.actions;

export default skillsSlice.reducer;
