import React, { useRef, useEffect } from 'react';
import Resume from '../components/resume/resume';
import ReactToPrint from 'react-to-print';
import Translator from '../components/shared/translator';
import './resumePage.css';

function ResumePage({ t }) {
	const translatorRef = useRef();
	const componentRef = useRef();

	useEffect(() => {
		// Store the original body background color
		const originalBackgroundColor = document.body.style.backgroundColor;

		// Change the body background color
		document.body.style.backgroundColor = 'lightblue';

		// Revert to the original color on component unmount
		return () => {
			document.body.style.backgroundColor = originalBackgroundColor;
		};
	}, []);

	return (
		<>
			<Resume t={t} translatorRef={translatorRef} componentRef={componentRef} />

			<div className='resume-actions'>
				<ReactToPrint
					trigger={() => <button>{t('Print Resume')}</button>}
					content={() => componentRef.current}
				/>
			</div>

			<Translator t={t} ref={translatorRef} />
		</>
	);
}

export default ResumePage;
