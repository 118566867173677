import React, { useState, useEffect } from 'react';
import './cookieConsent.css'; // Ensure this is linked for styling

const CookieConsent = ({ t, translator }) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const consent = localStorage.getItem('cookieConsent');
		if (consent !== 'given') {
			setIsVisible(true);
		}
	}, []);

	const handleAccept = () => {
		localStorage.setItem('cookieConsent', 'given');
		setIsVisible(false);
	};

	return (
		<div className={`cookie-consent ${isVisible ? 'enter' : 'exit'}`}>
			<span onContextMenu={(e) => translator.openTranslator('Cookie Consent', e)}>
				{t('Cookie Consent')}
			</span>
			<button
				onClick={handleAccept}
				className='accept-button'
				onContextMenu={(e) => translator.openTranslator('Accept Button', e)}>
				{t('Accept Button')}
			</button>
		</div>
	);
};

export default CookieConsent;
