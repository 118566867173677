import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../api';

// Async thunk for handling login
export const loginUser = createAsyncThunk(
	'users/login',
	async ({ username, password }, { rejectWithValue }) => {
		try {
			const response = await API.post('users/login', { username, password });
			const token = response.data.token;
			localStorage.setItem('token', token);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const getUserInfo = createAsyncThunk('users/userinfo', async (_, { rejectWithValue }) => {
	try {
		const response = await API.get('users/userinfo', null);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

const userSlice = createSlice({
	name: 'user',
	initialState: {
		userInfo: null,
		isLoading: false,
		error: null,
	},
	reducers: {
		logout: (state) => {
			state.userInfo = null;
			localStorage.removeItem('token');
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUser.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.isLoading = false;
				state.userInfo = action.payload;
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.isLoading = false;
				console.log(action);
				state.error = action.payload || 'Could not log in';
			})
			.addCase(getUserInfo.fulfilled, (state, action) => {
				state.userInfo = action.payload;
			})
			.addCase(getUserInfo.rejected, (state, action) => {
				state.userInfo = null;
				console.log(action);
			});
	},
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
