import React from 'react';
import './resumeSection.css';

function ResumeSection({ title, sections, t, translator }) {
	return (
		<div className='resume-section'>
			<h2 onContextMenu={(e) => translator.openTranslator(title, e)}>{t(title)}</h2>
			<ul className='resume-list'>
				{sections.map((section, index) => (
					<li className='resume-list-item' key={index}>
						{section.title && (
							<div
								className='resume-list-item-title'
								onContextMenu={(e) => translator.openTranslator(section.title, e)}>
								{t(section.title)}
							</div>
						)}
						{section.date && (
							<div
								className='resume-list-item-date'
								onContextMenu={(e) => translator.openTranslator(section.date, e)}>
								{t(section.date)}
							</div>
						)}
						{section.description && (
							<div
								className='resume-list-item-description'
								onContextMenu={(e) =>
									translator.openTranslator(section.description, e)
								}>
								{t(section.description)}
							</div>
						)}
						{section.subsections && (
							<ul className='resume-list resume-list-subsection'>
								{section.subsections.map((subsection, index) => (
									<li className='resume-list-item' key={index}>
										{subsection.title && (
											<b
												onContextMenu={(e) =>
													translator.openTranslator(subsection.title, e)
												}>
												{t(subsection.title)}:
											</b>
										)}
										{subsection.title && subsection.description && ' '}
										{subsection.description && (
											<span
												onContextMenu={(e) =>
													translator.openTranslator(
														subsection.description,
														e
													)
												}>
												{t(subsection.description)}
											</span>
										)}
									</li>
								))}
							</ul>
						)}
						{section.closing && (
							<div
								className='resume-list-item-description'
								onContextMenu={(e) =>
									translator.openTranslator(section.closing, e)
								}>
								{t(section.closing)}
							</div>
						)}
					</li>
				))}
			</ul>
		</div>
	);
}

export default ResumeSection;
