import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import languageReducer from './reducers/languageSlice';
import projectsReducer from './reducers/projectsSlice';
import skillsReducer from './reducers/skillsSlice';

const store = configureStore({
	reducer: {
		user: userReducer,
		language: languageReducer,
		project: projectsReducer,
		skill: skillsReducer,
	},
});

export default store;
