import React from 'react';
import './about.css';

function About({ t, translator }) {
	const opening = t('Opening About');
	const history = t('History About');
	const education = t('Education About');
	const hobbies = t('Hobbies About');
	const tradition = t('Tradition About');
	const closing = t('Closing About');

	return (
		<section id='about'>
			<div className='container'>
				<h2 onContextMenu={(e) => translator.openTranslator('About Me Navbar Header', e)}>
					{t('About Me Navbar Header')}
				</h2>
				{opening && (
					<p onContextMenu={(e) => translator.openTranslator('Opening About', e)}>
						{opening}
					</p>
				)}
				{history && (
					<p onContextMenu={(e) => translator.openTranslator('History About', e)}>
						{history}
					</p>
				)}
				{education && (
					<p onContextMenu={(e) => translator.openTranslator('Education About', e)}>
						{education}
					</p>
				)}
				{hobbies && (
					<p onContextMenu={(e) => translator.openTranslator('Hobbies About', e)}>
						{hobbies}
					</p>
				)}
				{tradition && (
					<p onContextMenu={(e) => translator.openTranslator('Tradition About', e)}>
						{tradition}
					</p>
				)}
				{closing && (
					<p onContextMenu={(e) => translator.openTranslator('Closing About', e)}>
						{closing}
					</p>
				)}
			</div>
		</section>
	);
}

export default About;
