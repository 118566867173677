import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../api';

// Async thunk for handling login
export const getAllLanguages = createAsyncThunk('languages', async (_, { rejectWithValue }) => {
	try {
		const response = await API.get('languages', null);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const getTranslations = createAsyncThunk(
	'languages/translations',
	async (languageCode, { rejectWithValue }) => {
		if (!languageCode) return {};
		try {
			const response = await API.get(`languages/translations/${languageCode}`);
			return response.data;
		} catch (error) {
			console.error('Error fetching translations:', error);
			return rejectWithValue(error.response.data);
		}
	}
);

export const updateTranslation = createAsyncThunk(
	'languages/translations/update',
	async ({ currentLanguage, key, translation }, { rejectWithValue }) => {
		try {
			const response = await API.put(`languages/translations/${currentLanguage}`, {
				key,
				translation,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const languageSlice = createSlice({
	name: 'language',
	initialState: {
		allLanguages: {},
		currentLanguage: '',
		translations: {},
		translationLoading: false,
		translationError: null,
	},
	reducers: {
		setLanguage: (state, action) => {
			state.currentLanguage = action.payload;

			if (state.currentLanguage === 'he') {
				document.body.classList.add('rtl');
			} else {
				document.body.classList.remove('rtl');
			}
		},
		setTranslations: (state, action) => {
			state.translations = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllLanguages.fulfilled, (state, action) => {
				state.allLanguages = action.payload;
			})
			.addCase(getAllLanguages.rejected, (state, action) => {
				state.allLanguages = { en: 'English' };
				console.error(action);
			})
			.addCase(getTranslations.pending, (state) => {
				state.translationLoading = true;
				state.translationError = null;
			})
			.addCase(getTranslations.fulfilled, (state, action) => {
				if (Object.keys(action.payload).length === 0) return;
				state.translationLoading = false;
				state.translations = action.payload;
			})
			.addCase(getTranslations.rejected, (state, action) => {
				state.translationLoading = false;
				state.translations = {};
				state.translationError = action.payload;
				console.error(action);
			});
	},
});

export const { setLanguage, setTranslations } = languageSlice.actions;

export default languageSlice.reducer;
