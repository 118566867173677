import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../redux/reducers/userSlice';
import './loginPage.css';
import Translator from '../components/shared/translator';

function LoginPage({ t }) {
	const translatorRef = useRef();
	const navigate = useNavigate();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();
	const { userInfo, isLoading, error } = useSelector((state) => state.user);

	useEffect(() => {
		if (userInfo) {
			navigate('/'); // Redirect to the homepage
		}
	}, [userInfo, navigate]);

	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(loginUser({ username, password }));
	};

	return (
		<>
			<div className='login-container'>
				<form onSubmit={handleSubmit} className='login-form'>
					<h2
						onContextMenu={(e) =>
							translatorRef.current.openTranslator('Login Title', e)
						}>
						{t('Login Title')}
					</h2>
					{error && <p className='error'>{error}</p>}
					<div className='input-group'>
						<label
							onContextMenu={(e) =>
								translatorRef.current.openTranslator('Login Username Input', e)
							}>
							{t('Login Username Input')}
						</label>
						<input
							type='text'
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							disabled={isLoading}
						/>
					</div>
					<div className='input-group'>
						<label
							onContextMenu={(e) =>
								translatorRef.current.openTranslator('Login Password Input', e)
							}>
							{t('Login Password Input')}
						</label>
						<input
							type='password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							disabled={isLoading}
						/>
					</div>
					<button
						type='submit'
						disabled={isLoading}
						onContextMenu={(e) =>
							isLoading
								? translatorRef.current.openTranslator('Login Logging in...', e)
								: translatorRef.current.openTranslator('Login Login Button', e)
						}>
						{isLoading ? t('Login Logging in...') : t('Login Login Button')}
					</button>
				</form>
			</div>

			<Translator t={t} ref={translatorRef} />
		</>
	);
}

export default LoginPage;
