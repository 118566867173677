import React from 'react';
import './contact.css';

function Contact({ t, translator }) {
	const contactInfo = [
		{
			title: t('Phone Number Contact'),
			value: t('Actual Phone Number Contact'),
		},
		{
			title: t('Email Contact'),
			value: 'emilleibovich@gmail.com',
		},
		{
			title: 'LinkedIn',
			value: t('Full Name Contact'),
			href: 'https://www.linkedin.com/in/emil-leibovich-7a8a451aa/',
		},
	];

	return (
		<section id='contact'>
			<h2 onContextMenu={(e) => translator.openTranslator('Contact Navbar Header', e)}>
				{t('Contact Navbar Header')}
			</h2>
			<ul className='contact-list'>
				{contactInfo.map((property, index) => (
					<li key={index}>
						<span onContextMenu={(e) => translator.openTranslator(property.title, e)}>
							{property.title}
							{': '}
						</span>
						<span onContextMenu={(e) => translator.openTranslator(property.value, e)}>
							{property.href ? (
								<a href={property.href}>{property.value}</a>
							) : (
								property.value
							)}
						</span>
					</li>
				))}
			</ul>
		</section>
	);
}

export default Contact;
