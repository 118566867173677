import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLanguages, setLanguage } from '../../redux/reducers/languageSlice';
import './navbar.css';

function Navbar({ t, translator }) {
	const [isOpen, setIsOpen] = useState(false);
	const [isAutoOpened, setIsAutoOpened] = useState(false);

	const dispatch = useDispatch();
	const { userInfo } = useSelector((state) => state.user);
	const { allLanguages, currentLanguage } = useSelector((state) => state.language);

	useEffect(() => {
		dispatch(getAllLanguages());
	}, [dispatch]);

	useEffect(() => {
		let timeoutId;

		// Function to check screen width and update menu state
		const handleResize = () => {
			if (window.innerWidth < 768) {
				// Set a timeout to delay the opening of the menu
				timeoutId = setTimeout(() => {
					setIsOpen(true);
					setIsAutoOpened(true);
				}, 1000); // Delay in milliseconds
			} else {
				setIsOpen(false); // Ensure the menu is closed on larger screens
				setIsAutoOpened(false);
			}
		};

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => {
			window.removeEventListener('resize', handleResize);
			clearTimeout(timeoutId);
		};
	}, []);

	const toggleMenu = () => {
		if (isAutoOpened) {
			setIsAutoOpened(false);
		}
		setIsOpen(!isOpen);
	};

	const selectLanguage = (code) => {
		if (code === currentLanguage) return;

		localStorage.setItem('currentLanguage', code);
		dispatch(setLanguage(code));
	};

	return (
		<header className='header'>
			<nav className='navbar'>
				<div className='burger-menu' onClick={toggleMenu}>
					<div></div>
					<div></div>
					<div></div>
				</div>

				<ul
					className={`nav-list ${isOpen ? 'open' : ''} ${
						isAutoOpened ? 'auto-open' : ''
					}`}>
					<li
						className={`nav-item ${userInfo ? 'logged-in' : ''}`}
						onContextMenu={(e) => translator.openTranslator('Emil Navbar Header', e)}>
						<a href='#introduction'>{t('Emil Navbar Header')}</a>
					</li>
					{/* <li className='nav-item'>
						<a href='#introduction'>Me+I</a>
					</li> */}
					<li
						className='nav-item'
						onContextMenu={(e) =>
							translator.openTranslator('About Me Navbar Header', e)
						}>
						<a href='#about'>{t('About Me Navbar Header')}</a>
					</li>
					<li
						className='nav-item'
						onContextMenu={(e) =>
							translator.openTranslator('Projects Navbar Header', e)
						}>
						<a href='#projects'>{t('Projects Navbar Header')}</a>
					</li>
					<li
						className='nav-item'
						onContextMenu={(e) =>
							translator.openTranslator('Contact Navbar Header', e)
						}>
						<a href='#contact'>{t('Contact Navbar Header')}</a>
					</li>
					<li
						className='nav-item'
						onContextMenu={(e) => translator.openTranslator('Resume Navbar Header', e)}>
						<Link to='/resume'>{t('Resume Navbar Header')}</Link>
					</li>
					<li className='nav-item'>
						<div className='language-dropdown'>
							<button
								className='dropbtn'
								disabled={true}
								onContextMenu={(e) =>
									translator.openTranslator('Languages Navbar Header', e)
								}>
								{t('Languages Navbar Header')}
							</button>
							<div className='dropdown-content'>
								{Object.keys(allLanguages).map((key) => (
									<button key={key} onClick={() => selectLanguage(key)}>
										{allLanguages[key]}
									</button>
								))}
							</div>
						</div>
					</li>
				</ul>
			</nav>
		</header>
	);
}

export default Navbar;
