import axios from 'axios';

const API = axios.create({
	baseURL: 'https://api.emilleibwitz.com/api/v1/',
	//baseURL: 'https://localhost:7103/api/v1/',
});

// Add a request interceptor
API.interceptors.request.use(
	function (config) {
		// Retrieve the token from storage
		const token = localStorage.getItem('token');

		// If the token is found, add it to the request's headers
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

export default API;
