import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../api';

// Async thunk for handling login
export const getProjects = createAsyncThunk('projects', async (_, { rejectWithValue }) => {
	try {
		const response = await API.get('projects', null);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const getKeyProjects = createAsyncThunk('projects/key', async (_, { rejectWithValue }) => {
	try {
		const response = await API.get('projects/key', null);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

const projectsSlice = createSlice({
	name: 'projects',
	initialState: {
		projects: [],
		keyProjects: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getProjects.fulfilled, (state, action) => {
				state.projects = action.payload;
			})
			.addCase(getProjects.rejected, (state, action) => {
				state.projects = [];
				console.error(action);
			})
			.addCase(getKeyProjects.fulfilled, (state, action) => {
				state.keyProjects = action.payload;
			})
			.addCase(getKeyProjects.rejected, (state, action) => {
				state.keyProjects = [];
				console.error(action);
			});
	},
});

//export const { } = projectsSlice.actions;

export default projectsSlice.reducer;
