import React, { useEffect } from 'react';
import ResumeSection from './resumeSection';
import { useDispatch, useSelector } from 'react-redux';
import { getSkills } from '../../redux/reducers/skillsSlice';
import { getKeyProjects } from '../../redux/reducers/projectsSlice';
import './resume.css';

function Resume({ t, translatorRef, componentRef }) {
	const translator = translatorRef.current;
	const dispatch = useDispatch();
	const { skills } = useSelector((state) => state.skill);
	const { keyProjects } = useSelector((state) => state.project);
	const { currentLanguage } = useSelector((state) => state.language);

	useEffect(() => {
		dispatch(getSkills());
		dispatch(getKeyProjects());
	}, [dispatch]);

	const isRTL = () => (currentLanguage === 'he' ? 'rtl' : '');

	const professionalExperience = {
		translator: translator,
		t: t,
		title: 'Resume Professional Experience Header',
		sections: [
			{
				title: 'Resume Professional Experience Everest Technologies Title',
				date: 'Resume Professional Experience Everest Technologies Date',
				description: 'Resume Professional Experience Everest Technologies Description',
				subsections: [
					{
						// Project Leadership
						title: 'Resume Professional Experience Everest Technologies Project Leadership Title',
						description:
							'Resume Professional Experience Everest Technologies Project Leadership Description',
					},
					{
						// Software Development
						title: 'Resume Professional Experience Everest Technologies Software Development Title',
						description:
							'Resume Professional Experience Everest Technologies Software Development Description',
					},
					{
						// Problem Solving
						title: 'Resume Professional Experience Everest Technologies Problem Solving Title',
						description:
							'Resume Professional Experience Everest Technologies Problem Solving Description',
					},
					{
						// Innovative Solutions
						title: 'Resume Professional Experience Everest Technologies Innovative Solutions Title',
						description:
							'Resume Professional Experience Everest Technologies Innovative Solutions Description',
					},
					{
						// Continuous Learning and Adaptation
						title: 'Resume Professional Experience Everest Technologies Continuous Learning and Adaptation Title',
						description:
							'Resume Professional Experience Everest Technologies Continuous Learning and Adaptation Description',
					},
				],
			},
		],
	};

	const militaryService = {
		translator: translator,
		t: t,
		title: 'Resume Military Service Header',
		sections: [
			{
				title: 'Resume Military Service Electro-optics Title',
				date: 'Resume Military Service Electro-optics Date',
				description: 'Resume Military Service Electro-optics Description',
				subsections: [
					{
						title: 'Resume Military Service Electro-optics Software Tool Development Title',
						description:
							'Resume Military Service Electro-optics Software Tool Development Description',
					},
					{
						title: 'Resume Military Service Electro-optics Technical Expertise Title',
						description:
							'Resume Military Service Electro-optics Technical Expertise Description',
					},
					{
						title: 'Resume Military Service Electro-optics Problem-Solving Under Pressure Title',
						description:
							'Resume Military Service Electro-optics Problem-Solving Under Pressure Description',
					},
					{
						title: 'Resume Military Service Electro-optics Understanding the Basics Title',
						description:
							'Resume Military Service Electro-optics Understanding the Basics Description',
					},
				],
			},
		],
	};

	const education = {
		translator: translator,
		t: t,
		title: 'Resume Education Header',
		sections: [
			{
				title: 'Resume Education High School Diploma Title',
				date: 'Resume Education High School Diploma Date',
				description: 'Resume Education High School Diploma Description',
			},
			{
				title: 'Resume Education Passionate Self-Learner Title',
				date: 'Resume Education Passionate Self-Learner Date',
				description: 'Resume Education Passionate Self-Learner Description',
				subsections: [
					{
						title: 'Resume Education Passionate Self-Learner Comprehensive Understanding Title',
						description:
							'Resume Education Passionate Self-Learner Comprehensive Understanding Description',
					},
					{
						title: 'Resume Education Passionate Self-Learner Different sources Title',
						description:
							'Resume Education Passionate Self-Learner Different sources Description',
					},
					{
						title: 'Resume Education Passionate Self-Learner Always on top Title',
						description:
							'Resume Education Passionate Self-Learner Always on top Description',
					},
				],
			},
		],
	};

	const projects = {
		translator: translator,
		t: t,
		title: 'Resume Key Projects Header',
		sections: keyProjects.map((project) => {
			const projectSectionMapping = ['Description', 'Technologies', 'Challenges', 'Impact'];
			const subsectionValues = [
				project.overallDescription,
				project.technologiesDescription,
				project.challengesDescription,
				project.impactDescription,
			];

			return {
				title: project.title,
				subsections: subsectionValues
					.filter((section) => section) // Ensure the section is not null or undefined
					.map((section, index) => {
						return {
							title: `Resume Key Projects Subsection ${projectSectionMapping[index]} Title`,
							description: section,
						};
					}),
			};
		}),
	};

	const personalInterests = {
		translator: translator,
		t: t,
		title: 'Resume Personal Interests Header',
		sections: [
			{
				description: 'Resume Personal Interests Description',
				subsections: [
					{
						title: 'Resume Personal Interests Technology and History Title',
						description: 'Resume Personal Interests Technology and History Description',
					},
					{
						title: 'Resume Personal Interests Story Writing Title',
						description: 'Resume Personal Interests Story Writing Description',
					},
					{
						title: 'Resume Personal Interests Psychology and Philosophy Title',
						description:
							'Resume Personal Interests Psychology and Philosophy Description',
					},
					{
						title: 'Resume Personal Interests Mindfulness Practices Title',
						description: 'Resume Personal Interests Mindfulness Practices Description',
					},
					{
						title: 'Resume Personal Interests Lifelong Learner Title',
						description: 'Resume Personal Interests Lifelong Learner Description',
					},
				],
				closing: 'Resume Personal Interests Closing',
			},
		],
	};

	const contactInfo = {
		translator: translator,
		t: t,
		title: 'Resume Contact Information Header',
		properties: [
			{
				title: 'Phone Number Contact',
				value: 'Actual Phone Number Contact',
			},
			{
				title: 'Email Contact',
				value: 'emilleibovich@gmail.com',
			},
			{
				title: 'Website',
				value: 'www.emilleibwitz.com',
				href: 'https://www.emilleibwitz.com',
			},
			{
				title: 'LinkedIn',
				value: 'Full Name Contact',
				href: 'https://www.linkedin.com/in/emil-leibovich-7a8a451aa/',
			},
		],
	};

	return (
		<div className={`resume-container ${isRTL()}`} ref={componentRef}>
			<div className='header-section'>
				<h1 onContextMenu={(e) => translator.openTranslator('Resume Header', e)}>
					{t('Resume Header')}
				</h1>
			</div>

			<div className='resume-section professional-summary'>
				<h2
					onContextMenu={(e) =>
						translator.openTranslator('Resume Professional Summary Title', e)
					}>
					{t('Resume Professional Summary Title')}
				</h2>
				<p
					onContextMenu={(e) =>
						translator.openTranslator('Resume Professional Summary Summary', e)
					}>
					{t('Resume Professional Summary Summary')}
				</p>
				<p
					onContextMenu={(e) =>
						translator.openTranslator('Resume Professional Summary Drive', e)
					}>
					{t('Resume Professional Summary Drive')}
				</p>
				<p
					onContextMenu={(e) =>
						translator.openTranslator('Resume Professional Summary Motivation', e)
					}>
					{t('Resume Professional Summary Motivation')}
				</p>
			</div>

			<div className='resume-section skills-section'>
				<h2 onContextMenu={(e) => translator.openTranslator('Resume Skills Title', e)}>
					{t('Resume Skills Title')}
				</h2>
				<ul className='skills-list'>
					{Object.keys(skills).map((key) => (
						<li key={key}>
							<b
								onContextMenu={(e) =>
									translator.openTranslator(`Skill Category ${key}`, e)
								}>
								{t(`Skill Category ${key}`)}:
							</b>{' '}
							<span>{skills[key].join(', ')}</span>
						</li>
					))}
				</ul>
			</div>

			{ResumeSection(professionalExperience)}

			{ResumeSection(militaryService)}

			{ResumeSection(education)}

			{ResumeSection(projects)}

			{ResumeSection(personalInterests)}

			<div className='contact-section'>
				<h2 onContextMenu={(e) => translator.openTranslator(contactInfo.title, e)}>
					{t(contactInfo.title)}
				</h2>
				<ul className='contact-list'>
					{contactInfo.properties.map((property, index) => (
						<li key={index}>
							<span
								onContextMenu={(e) => translator.openTranslator(property.title, e)}>
								{t(property.title)}
							</span>
							{': '}
							<span
								onContextMenu={(e) => translator.openTranslator(property.value, e)}>
								{property.href ? (
									<a href={property.href}>{t(property.value)}</a>
								) : (
									t(property.value)
								)}
							</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Resume;
