import React from 'react';
import { Link } from 'react-router-dom';
import './notFound.css'; // Assuming you have some basic CSS

const NotFound = () => {
	return (
		<div className='NotFound'>
			<h1>404</h1>
			<p>Oops! We can't seem to find the page you are looking for.</p>
			<Link to='/'>Go Home</Link>
		</div>
	);
};

export default NotFound;
