import React from 'react';
import './loader.css';

function Loader() {
	return (
		<div className='loader-container'>
			<div className='loader'></div>
		</div>
	);
}

export default Loader;
