import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslations } from '../redux/reducers/languageSlice';

const useTranslations = () => {
	const dispatch = useDispatch();
	const { currentLanguage, translations, translationLoading, translationError } = useSelector(
		(state) => state.language
	);

	useEffect(() => {
		dispatch(getTranslations(currentLanguage));
	}, [currentLanguage, dispatch]);

	const t = (key) => translations[key] || key;

	return { t, loading: translationLoading, error: translationError };
};

export default useTranslations;
