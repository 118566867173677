import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainPage from './pages/mainPage';
import LoginPage from './pages/loginPage';
import ResumePage from './pages/resumePage';
import NotFound from './pages/shared/notFound';
import Loader from './pages/shared/loader';

import { useDispatch } from 'react-redux';
import { setLanguage } from './redux/reducers/languageSlice';
import { getUserInfo } from './redux/reducers/userSlice';
import useTranslations from './hooks/useTranslations';

import './App.css';

function App() {
	const dispatch = useDispatch();
	const { t, loading } = useTranslations();

	useEffect(() => {
		const userToken = localStorage.getItem('token');
		const language = localStorage.getItem('currentLanguage');

		if (userToken) {
			dispatch(getUserInfo(userToken));
		}

		dispatch(setLanguage(language || 'en'));
	}, [dispatch]);

	if (loading) return <Loader />;

	return (
		<Router>
			<Routes>
				<Route path='/' element={<MainPage t={t} />} />
				<Route path='/login' element={<LoginPage t={t} />} />
				<Route path='/resume' element={<ResumePage t={t} />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</Router>
	);
}

export default App;

