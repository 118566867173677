import About from '../components/mainPage/about';
import Contact from '../components/mainPage/contact';
import Navbar from '../components/mainPage/navbar';
import Introduction from '../components/mainPage/introduction';
import Projects from '../components/mainPage/projects';
import CookieConsent from '../components/shared/cookieConsent';
import Translator from '../components/shared/translator';
import { useRef } from 'react';
import './mainPage.css';

function MainPage({ t }) {
	const translatorRef = useRef();

	return (
		<>
			<Navbar t={t} translator={translatorRef.current} />
			<Introduction />
			<About t={t} translator={translatorRef.current} />
			<Projects t={t} translator={translatorRef.current} />
			<Contact t={t} translator={translatorRef.current} />
			<CookieConsent t={t} translator={translatorRef.current} />

			<Translator t={t} ref={translatorRef} />
		</>
	);
}

export default MainPage;
