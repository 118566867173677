import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../../redux/reducers/projectsSlice';
import './projects.css';

function Projects({ t, translator }) {
	const dispatch = useDispatch();
	const { projects } = useSelector((state) => state.project);

	useEffect(() => {
		dispatch(getProjects());
	}, [dispatch]);

	return (
		<section id='projects'>
			<h2
				className='text-center'
				onContextMenu={(e) => translator.openTranslator('Projects Navbar Header', e)}>
				{t('Projects Navbar Header')}
			</h2>
			<div className='container-fluid mt-4'>
				<div className='row'>
					{projects.map((project, id) => (
						<div key={id} className='col-lg-3 col-md-4 col-sm-6 mb-4'>
							<div className='card-container'>
								<Card>
									{project.imageUrl && (
										<Card.Img variant='top' src={project.imageUrl} />
									)}
									<Card.Body>
										<Card.Title
											onContextMenu={(e) =>
												translator.openTranslator(project.title, e)
											}>
											{t(project.title)}
										</Card.Title>
										<Card.Text
											onContextMenu={(e) =>
												translator.openTranslator(
													project.overallDescription,
													e
												)
											}>
											{t(project.overallDescription)}
										</Card.Text>
										<div className='tech-tags'>
											{project.tags.map((tag, id) => (
												<div key={id} className='tech-tag'>
													{tag.skill.name}
												</div>
											))}
										</div>
										{project.projectUrl && (
											<Button
												variant='primary'
												href={project.projectUrl}
												onContextMenu={(e) =>
													translator.openTranslator('View Project', e)
												}>
												{t('View Project')}
											</Button>
										)}
										<div className='project-completion-status'>
											{project.isComplete ? (
												<div
													className='complete-badge'
													onContextMenu={(e) =>
														translator.openTranslator(
															'Complete Project',
															e
														)
													}>
													{t('Complete Project')}
												</div>
											) : (
												<div
													className='incomplete-badge'
													onContextMenu={(e) =>
														translator.openTranslator(
															'In Progress Project',
															e
														)
													}>
													{t('In Progress Project')}
												</div>
											)}
										</div>
									</Card.Body>
								</Card>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default Projects;
