import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTranslation } from '../../redux/reducers/languageSlice';
import './translator.css';

const Translator = forwardRef(({ t }, ref) => {
	const [isActive, setIsActive] = useState(false);
	const [key, setKey] = useState('');
	const [currentTranslation, setCurrentTranslation] = useState('');
	const [newTranslation, setNewTranslation] = useState('');

	const { currentLanguage } = useSelector((state) => state.language);
	const { userInfo } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const subwindowRef = useRef(null);

	// Use useImperativeHandle to expose the openTranslator method to parent components
	useImperativeHandle(ref, () => ({
		openTranslator: (key, event = null) => {
			if (!userInfo) return;

			if (event) {
				event.preventDefault();
			}

			setKey(key);
			setCurrentTranslation(t(key));
			setNewTranslation('');
			setIsActive(true);
		},
	}));

	const handleNewTranslationChange = (e) => {
		setNewTranslation(e.target.value);
	};

	const handleConfirm = () => {
		dispatch(updateTranslation({ currentLanguage, key, translation: newTranslation }));
		setIsActive(false);
	};

	const handleCancel = () => {
		setIsActive(false);
	};

	const dragMouseDown = (e) => {
		// Check if the target is one of the elements that should not start the drag
		if (e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON') {
			return; // Do not start the drag if an input or button was clicked
		}

		e.preventDefault();
		let pos1 = 0,
			pos2 = 0,
			pos3 = e.clientX,
			pos4 = e.clientY;

		function elementDrag(event) {
			event.preventDefault();
			// Calculate the new cursor position:
			pos1 = pos3 - event.clientX;
			pos2 = pos4 - event.clientY;
			pos3 = event.clientX;
			pos4 = event.clientY;
			// Set the element's new position:
			subwindowRef.current.style.top = subwindowRef.current.offsetTop - pos2 + 'px';
			subwindowRef.current.style.left = subwindowRef.current.offsetLeft - pos1 + 'px';
		}

		function closeDragElement() {
			// Stop moving when mouse button is released:
			document.onmouseup = null;
			document.onmousemove = null;
		}

		document.onmousemove = elementDrag;
		document.onmouseup = closeDragElement;
	};

	if (!isActive || !userInfo) {
		return null;
	}

	return (
		<div className='translator-subwindow' ref={subwindowRef} onMouseDown={dragMouseDown}>
			<div className='translator-fields'>
				<input
					type='text'
					value={currentTranslation}
					readOnly
					className='translation-input'
				/>
				<input
					type='text'
					placeholder={t('Translator translation hint')}
					value={newTranslation}
					onChange={handleNewTranslationChange}
					className='translation-input'
					onContextMenu={(e) =>
						ref.current.openTranslator('Translator translation hint', e)
					}
				/>
			</div>
			<div className='translator-buttons'>
				<button
					onClick={handleConfirm}
					className='translator-button confirm'
					onContextMenu={(e) =>
						ref.current.openTranslator('Translator Confirm Button', e)
					}>
					{t('Translator Confirm Button')}
				</button>
				<button
					onClick={handleCancel}
					className='translator-button cancel'
					onContextMenu={(e) =>
						ref.current.openTranslator('Translator Cancel Button', e)
					}>
					{t('Translator Cancel Button')}
				</button>
			</div>
		</div>
	);
});

export default Translator;
